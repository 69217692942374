exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-about-index-js": () => import("./../../../src/templates/about/index.js" /* webpackChunkName: "component---src-templates-about-index-js" */),
  "component---src-templates-blank-index-js": () => import("./../../../src/templates/blank/index.js" /* webpackChunkName: "component---src-templates-blank-index-js" */),
  "component---src-templates-cart-index-js": () => import("./../../../src/templates/cart/index.js" /* webpackChunkName: "component---src-templates-cart-index-js" */),
  "component---src-templates-category-index-js": () => import("./../../../src/templates/category/index.js" /* webpackChunkName: "component---src-templates-category-index-js" */),
  "component---src-templates-checkout-index-js": () => import("./../../../src/templates/checkout/index.js" /* webpackChunkName: "component---src-templates-checkout-index-js" */),
  "component---src-templates-contact-index-js": () => import("./../../../src/templates/contact/index.js" /* webpackChunkName: "component---src-templates-contact-index-js" */),
  "component---src-templates-faq-index-js": () => import("./../../../src/templates/faq/index.js" /* webpackChunkName: "component---src-templates-faq-index-js" */),
  "component---src-templates-findings-index-js": () => import("./../../../src/templates/findings/index.js" /* webpackChunkName: "component---src-templates-findings-index-js" */),
  "component---src-templates-front-page-index-js": () => import("./../../../src/templates/front-page/index.js" /* webpackChunkName: "component---src-templates-front-page-index-js" */),
  "component---src-templates-login-index-js": () => import("./../../../src/templates/login/index.js" /* webpackChunkName: "component---src-templates-login-index-js" */),
  "component---src-templates-my-account-adress-index-js": () => import("./../../../src/templates/my-account-adress/index.js" /* webpackChunkName: "component---src-templates-my-account-adress-index-js" */),
  "component---src-templates-my-account-details-index-js": () => import("./../../../src/templates/my-account-details/index.js" /* webpackChunkName: "component---src-templates-my-account-details-index-js" */),
  "component---src-templates-my-account-dowload-index-js": () => import("./../../../src/templates/my-account-dowload/index.js" /* webpackChunkName: "component---src-templates-my-account-dowload-index-js" */),
  "component---src-templates-my-account-index-js": () => import("./../../../src/templates/my-account/index.js" /* webpackChunkName: "component---src-templates-my-account-index-js" */),
  "component---src-templates-my-account-orders-index-js": () => import("./../../../src/templates/my-account-orders/index.js" /* webpackChunkName: "component---src-templates-my-account-orders-index-js" */),
  "component---src-templates-my-account-subscription-index-js": () => import("./../../../src/templates/my-account-subscription/index.js" /* webpackChunkName: "component---src-templates-my-account-subscription-index-js" */),
  "component---src-templates-opinion-index-js": () => import("./../../../src/templates/opinion/index.js" /* webpackChunkName: "component---src-templates-opinion-index-js" */),
  "component---src-templates-post-single-index-js": () => import("./../../../src/templates/postSingle/index.js" /* webpackChunkName: "component---src-templates-post-single-index-js" */),
  "component---src-templates-product-categoris-index-js": () => import("./../../../src/templates/productCategoris/index.js" /* webpackChunkName: "component---src-templates-product-categoris-index-js" */),
  "component---src-templates-product-index-js": () => import("./../../../src/templates/product/index.js" /* webpackChunkName: "component---src-templates-product-index-js" */),
  "component---src-templates-product-single-index-js": () => import("./../../../src/templates/productSingle/index.js" /* webpackChunkName: "component---src-templates-product-single-index-js" */),
  "component---src-templates-quiz-index-js": () => import("./../../../src/templates/quiz/index.js" /* webpackChunkName: "component---src-templates-quiz-index-js" */),
  "component---src-templates-recovery-password-index-js": () => import("./../../../src/templates/recoveryPassword/index.js" /* webpackChunkName: "component---src-templates-recovery-password-index-js" */),
  "component---src-templates-reset-password-index-js": () => import("./../../../src/templates/resetPassword/index.js" /* webpackChunkName: "component---src-templates-reset-password-index-js" */),
  "component---src-templates-search-index-js": () => import("./../../../src/templates/search/index.js" /* webpackChunkName: "component---src-templates-search-index-js" */),
  "component---src-templates-shop-index-js": () => import("./../../../src/templates/shop/index.js" /* webpackChunkName: "component---src-templates-shop-index-js" */),
  "component---src-templates-subscription-info-index-js": () => import("./../../../src/templates/subscriptionInfo/index.js" /* webpackChunkName: "component---src-templates-subscription-info-index-js" */),
  "component---src-templates-thank-you-index-js": () => import("./../../../src/templates/thankYou/index.js" /* webpackChunkName: "component---src-templates-thank-you-index-js" */)
}

